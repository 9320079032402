// ActionTypes.js
'use strict';

export const LOGOUT = 'LOGOUT';
export const PERSIST_NOW = 'PERSIST_NOW';

export const SET_ME_DATA = 'SET_ME_DATA';
export const MERGE_ME_DATA = 'MERGE_ME_DATA';
export const MERGE_FLIGHT_CHECK_DATA = 'MERGE_FLIGHT_CHECK_DATA';
export const UPDATE_SETTINGS_DATA = 'UPDATE_SETTINGS_DATA';
export const RESTORE_ME_DATA = 'RESTORE_ME_DATA';

export const MERGE_OPERATION_DATA = 'MERGE_OPERATION_DATA';
export const SET_OPERATION_DATA = 'SET_OPERATION_DATA';
export const PUSH_OPERATION_DATA = 'PUSH_OPERATION_DATA';
export const REMOVE_OPERATION_DATA = 'REMOVE_OPERATION_DATA';
export const RESET_OPERATION_DATA = 'RESET_OPERATION_DATA';
export const RESTORE_OPERATION_DATA = 'RESTORE_OPERATION_DATA';
export const CLEAR_OPERATION_DATA = 'CLEAR_OPERATION_DATA';

export const ADD_MODAL = 'ADD_MODAL';
export const ADD_MODAL_NEXT_ID = 'ADD_MODAL_NEXT_ID';
export const REMOVE_MODALS = 'REMOVE_MODALS';
export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';
export const RESTORE_MODALS = 'RESTORE_MODALS';
export const CLEAR_MODALS = 'CLEAR_MODALS';

export const SET_NETWORKING_FETCHING = 'SET_NETWORKING_FETCHING';
export const SET_NETWORKING_SUCCESS = 'SET_NETWORKING_SUCCESS';
export const SET_NETWORKING_ERROR = 'SET_NETWORKING_ERROR';
export const SET_NETWORKING_FETCHING_BATCH = 'SET_NETWORKING_FETCHING_BATCH';
export const SET_NETWORKING_SUCCESS_BATCH = 'SET_NETWORKING_SUCCESS_BATCH';
export const SET_NETWORKING_ERROR_BATCH = 'SET_NETWORKING_ERROR_BATCH';
export const SET_NETWORKING_IDLE = 'SET_NETWORKING_IDLE';
export const CLEAR_NETWORKING_NODES = 'CLEAR_NETWORKING_NODES';

export const ADD_LIST_ITEMS = 'ADD_LIST_ITEMS';
export const REMOVE_LIST_ITEMS = 'REMOVE_LIST_ITEMS';
export const SET_LIST_ITEMS = 'SET_LIST_ITEMS';
export const RESTORE_LIST_ITEMS = 'RESTORE_LIST_ITEMS';

export const ADD_USERS = 'ADD_USERS';
export const MERGE_USER = 'MERGE_USER';
export const SET_USER = 'SET_USER';

export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_MESSAGES = 'ADD_MESSAGES';
export const SET_MESSAGE = 'SET_MESSAGE';
export const REMOVE_MESSAGES = 'REMOVE_MESSAGES';
export const CLEAR_ALL_MESSAGES = 'CLEAR_ALL_MESSAGES';
export const CHANGE_MESSAGE_ID = 'CHANGE_MESSAGE_ID';

export const MERGE_MESSAGE_PACKS = 'MERGE_MESSAGE_PACKS';

export const ADD_CHATROOMS = 'ADD_CHATROOMS';
export const MERGE_CHATROOM_DATA = 'MERGE_CHATROOM_DATA';

export const ADD_GIFTS = 'ADD_GIFTS';
export const ADD_GIFT_CATEGORIES = 'ADD_GIFT_CATEGORIES';

export const ADD_DIAMOND_PACKS = 'ADD_DIAMOND_PACKS';

export const ADD_ORDERS = 'ADD_ORDERS';
export const MERGE_ORDER_DATA = 'MERGE_ORDER_DATA';
export const RESTORE_ORDER_DATA = 'RESTORE_ORDER_DATA';
export const CLEAR_ORDER_DATA = 'CLEAR_ORDER_DATA';
export const PUSH_ROUTE_HISTORY = 'PUSH_ROUTE_HISTORY';
export const RESTORE_ROUTE_HISTORY = 'RESTORE_ROUTE_HISTORY';
export const CLEAR_ROUTE_HISTORY = 'CLEAR_ROUTE_HISTORY';

export const ADD_JOURNAL_LOGS = 'ADD_JOURNAL_LOGS';

export const ADD_CP_SCORE_BOOK_RECORDS = 'ADD_CP_SCORE_BOOK_RECORDS';

export const MERGE_REMOTE_CONFIG_DATA = 'MERGE_REMOTE_CONFIG_DATA';

export const ADD_HASHTAGS = 'ADD_HASHTAGS';
export const ADD_CATEGORIES = 'ADD_CATEGORIES';

export const MERGE_REVENUE_DATA = 'MERGE_REVENUE_DATA';

export const MERGE_LIVE_STREAM_DATA = 'MERGE_LIVE_STREAM_DATA';
export const MERGE_LIVE_STREAMS_DATA = 'MERGE_LIVE_STREAMS_DATA';
export const REMOVE_LIVE_STREAM_DATA = 'REMOVE_LIVE_STREAM_DATA';
export const SET_LIVE_STREAM_DATA = 'SET_LIVE_STREAM_DATA';

export const ADD_GOAL = 'ADD_GOAL';
export const ADD_GOALS = 'ADD_GOALS';

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';
export const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';

export const ADD_INTERCOM_ARTICLES = 'ADD_INTERCOM_ARTICLES';

export const MERGE_ASSET_DATA = 'MERGE_ASSET_DATA';
export const REMOVE_ASSET_DATA = 'REMOVE_ASSET_DATA';
export const RESTORE_ASSET_DATA = 'RESTORE_ASSET_DATA';

export const ADD_SHORT_LINKS = 'ADD_SHORT_LINKS';

export const ADD_BACKPACK_ITEMS = 'ADD_BACKPACK_ITEMS';
export const MERGE_BACKPACK_ITEMS = 'MERGE_BACKPACK_ITEMS';

export const MERGE_GAME_DATA = 'MERGE_GAME_DATA';
export const REMOVE_GAME_DATA = 'REMOVE_GAME_DATA';

export const SET_EXPIRE_TIMESTAMP = 'SET_EXPIRE_TIMESTAMP';

export const MERGE_PRODUCT_DATA = 'MERGE_PRODUCT_DATA';
export const REMOVE_PRODUCT_DATA = 'REMOVE_PRODUCT_DATA';

export const MERGE_EXTERNAL_DATA = 'MERGE_EXTERNAL_DATA';
